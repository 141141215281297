import React from 'react'
import { useNavigate } from 'react-router-dom';

function BlogDetail({ blog }) {

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Bir önceki sayfaya gider
  };


  return (
    <div className='w-full h-auto bg-black flex flex-col items-center scroll-smooth'>
      <div className='w-full h-auto min-h-screen flex justify-center'>
        <div className='w-2/3 max-sm:11/12 max-md:w-10/12 h-auto'>
        <div className='w-full h-24 flex items-center justify-start'>
          <button onClick={goBack} className='w-10 h-10 hover:bg-white/20 rounded-full'>
        <i class="fa-solid fa-chevron-left text-xl text-white"></i>
          </button>
        </div>
          <img
            src={blog.image}
            alt='blogimage'
            className='w-full h-[50vh] max-sm:h-[25vh] object-cover rounded-lg'
          />
          <div className='w-full py-2 pt-8 flex items-center justify-start gap-4 *:font-semibold'>
            <div className='text-[14px] text-white/70 leading-none'><i class="fa-regular fa-clock mr-2"></i>{blog.tarih}</div>
            <div className='text-[14px] text-white/70 leading-none'><i class="fa-regular fa-clock mr-2"></i>{blog.tarih}</div>
          </div>
          <div className='w-full py-2 text-[33px] font-semibold text-white leading-none'>{blog.title}</div>
          <div className='w-full py-2 text-xl text-white/80' dangerouslySetInnerHTML={{ __html: blog.content }}></div>
          <div className='w-full py-8 flex flex-wrap items-center gap-2 *:px-4 *:py-1 *:bg-white/20 *:text-xs *:text-white *:rounded-full *:cursor-pointer'>
            {blog.tags.map((t, index) => (
              <div key={index}>{t}</div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default BlogDetail