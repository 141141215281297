import React, { useEffect, useState } from 'react'
import "./home.css"
import { ReactTyped } from 'react-typed'
import Work from './Work'
import 'aos/dist/aos.css';
import AOS from 'aos';
import Blog from './Blog';
import Contact from './Contact';
import { Pivot as Hamburger } from 'hamburger-react'

function Home() {

    const [isOpen, setOpen] = useState(false)

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth'
            });
        }
        setOpen(false);
    };


    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    return (
        <div className='w-full h-auto  bg-black flex flex-col items-center scroll-smooth'>
            <nav className='w-full py-4 max-sm:px-8 mx-4 flex fixed mix-blend-difference max-sm:mix-blend-normal z-50'>
                <div className='w-3/12 px-16 py-2 flex font-inter text-white text-custom font-semibold max-sm:hidden'>Emirhan Körhan</div>
                <div className='w-2/12 py-2 font-inter text-white text-custom font-medium text-start max-sm:hidden'>Samsun, Turkey <br /> Front-End Developer</div>
                <div className='w-1/2 items-center justify-center max-sm:justify-start hidden max-sm:flex z-40'>
                    <Hamburger color='#ffffff' toggled={isOpen} toggle={setOpen} rounded size={25}></Hamburger>
                </div>
                <div className='w-5/12 pr-40 py-2 flex-1 flex justify-evenly gap-10 *:font-medium *:cursor-pointer *:font-inter *:text-white *:text-xs max-sm:hidden'>
                    <div className='hover:text-gray-300' onClick={() => scrollToSection('about')}>Hakkımda</div>
                    <div className='hover:text-gray-300' onClick={() => scrollToSection('gallery')}>Galeri</div>
                    <div className='hover:text-gray-300' onClick={() => scrollToSection('blog')}>Blog</div>
                    <div className='hover:text-gray-300' onClick={() => scrollToSection('contact')}>İletişim</div>
                </div>

                <div className='w-2/12 max-sm:w-1/2 flex items-center justify-center max-sm:justify-end'>
                    <div className='relative w-10 h-10 flex items-center justify-center z-40 group'>
                        <img
                            src='/profile.jpg'
                            alt='photo222'
                            className='absolute w-10 h-10 rounded-full'
                        />
                        <div className='absolute top-16 text-2xs py-1 px-4 bg-white/15 rounded-md font-inter cursor-pointer text-white mb-12 opacity-0 transition-opacity duration-300 group-hover:opacity-100'>
                            Open for new work
                        </div>
                        <div className='absolute w-3 h-3 bg-green-500 bottom-0 left-0 rounded-full'></div>
                        <div className='absolute w-3 h-3 bg-green-500 bottom-0 left-0 rounded-full animate-ping'></div>
                    </div>

                    {isOpen && (<div className="w-[200rem] h-[200rem] rounded-full fixed -top-[100rem] -left-[100rem] flex items-center justify-center bg-black anmt">
                        <div className="w-full h-screen flex flex-col items-center justify-center fixed top-0 left-0">
                            <div className='w-2/3 h-auto *:font-inter *:text-white flex flex-col *:flex *:items-center *:justify-start *:text-4xl *:font-semibold tag-menu'>
                                <div className='hover:text-gray-300' onClick={() => scrollToSection('about')}>HAKKIMDA</div>
                                <div className='hover:text-gray-300' onClick={() => scrollToSection('gallery')}>GALERİ</div>
                                <div className='hover:text-gray-300' onClick={() => scrollToSection('blog')}>BLOG</div>
                                <div className='hover:text-gray-300' onClick={() => scrollToSection('contact')}>İLETİŞİM</div>
                            </div>
                            <div className='w-full py-8 absolute bottom-12 flex items-center justify-between *:text-white *:font-inter px-8 tag-menu text-custom'>
                                <div className=''>Samsun, Turkey</div>
                                <div className='flex items-center gap-4'>
                                    <div><a href='https://www.instagram.com/emir.krhan'>ig</a></div>
                                    <div><a href='https://www.behance.net/emirhankrhan'>bh</a></div>
                                    <div><a href='https://github.com/emirhankorhan'>gh</a></div>
                                </div>

                            </div>
                            <div className="space-bott"></div>
                        </div>
                    </div>)}


                </div>
            </nav>
            <div id='about' className='w-full h-[90vh] bg-black flex max-sm:flex-col items-center max-sm:justify-center'>
                <div className='w-3/12 max-sm:hidden'></div>
                <div className='w-2/12 max-sm:w-full py-2 max-sm:px-6 font-inter text-white text-custom font-normal text-start'>Web Geliştirme <br /> Grafik Tasarım <br /> Yaratıcı Yazarlık</div>
                <ReactTyped className='w-7/12 max-sm:w-full max-sm:h-[40vh] max-sm:flex max-sm:items-start max-sm:pl-6 max-sm:pr-6 pr-20 h-52 font-inter text-white text-[44px] leading-[48px] font-medium text-start'
                    showCursor={false}
                    strings={["Merhaba! Ben Emir, Samsun'da yaşıyorum. Front-End web geliştirme ve grafik tasarım üzerine çalışıyorum."]}
                    typeSpeed={20} />
            </div>


            <Work />

            <div id='gallery' data-aos="fade-up" className='w-[95%] px-16 max-sm:px-2 pt-24 pb-20 text-[100px] max-sm:text-[60px] text-end leading-[100px] max-sm:leading-[55px] text-white font-inter font-medium'>FARKLI <br /> ÇALIŞMALAR</div>

            <div data-aos="fade-up" className='border-t border-solid border-gray-700 w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Eco Banking App</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Eco is revolutionizing the future of banking by merging sustainability with
                        cutting-edge technology.</div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Brand Design</div>

                </div>
                <img
                    src='/eco.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>


            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Blake Burger</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Blake Burger brings you a bold and unforgettable taste experience with every bite. Crafted from the finest ingredients and packed with flavor, Blake Burger is where quality meets indulgence.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Advertisement Design</div>

                </div>
                <img
                    src='/blake.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>









            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Flee Mobile Health</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Flee Mobile Health is transforming healthcare access by bringing essential medical services right to your fingertips.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>User Interface Design</div>

                </div>
                <img
                    src='/flee.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>







            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2023</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Missing Eye</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Missing Eye captivates with its haunting narrative, and the poster design brings that eerie mystery to life.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Poster Design</div>

                </div>
                <img
                    src='/missingeye.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>













            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Alive</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Alive takes the zombie genre to new heights, and the movie banner design captures the intense, heart-pounding action of survival.                                                            </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Movie Banner Design</div>

                </div>
                <img
                    src='/alive.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>









            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Buzz Social Media App</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Buzz is redefining social connection by creating a vibrant, dynamic space for real-time conversations and community building.                                                            </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Brand Design</div>

                </div>
                <img
                    src='/buzz.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>








            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2023</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Devil</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Devil delves into the dark and twisted tale of a woman who makes a pact with the devil, trading her soul for power and desire.                                                            </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Movie Banner Design</div>

                </div>
                <img
                    src='/devil.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>




            <Blog id='blog' />
            <Contact id='contact' />


        </div>
    )
}

export default Home