import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import 'aos/dist/aos.css';
import AOS from 'aos';

function Contact({id}) {

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    const [buttonText, setButtonText] = useState('Gönder');

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'İsim en az 3 karakter olmalıdır')
            .max(50, 'İsim en fazla 50 karakter olabilir')
            .required('İsim zorunludur'),

        email: Yup.string()
            .email('Geçerli bir e-posta adresi girin')
            .required('E-posta adresi zorunludur'),

        message: Yup.string()
            .min(10, 'Mesaj en az 10 karakter olmalıdır')
            .required('Mesaj zorunludur'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            emailjs.send('service_vlsmv33', 'template_iww8mm5', {
                user_name: values.name,
                user_email: values.email,
                message: values.message,
            }, 'pRivggAPeJPf9i9Oe')
                .then((result) => {
                    console.log(result.text);
                    setButtonText(<i class="fa-regular fa-circle-check text-green-600 text-icon"></i>);
                    setTimeout(() => {
                        setButtonText('Gönder');
                    }, 5000);
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                });
        },
    });


    return (
        <div id={id} className='w-full h-auto flex flex-col items-center'>
            <div data-aos="fade-up" className='w-[95%] px-16 max-sm:px-2 pt-36 pb-20 text-[100px] max-sm:text-[60px] text-start leading-[100px] max-sm:leading-[55px] text-white font-inter font-medium'>BİRLİKTE <br /> ÇALIŞALIM</div>

            <div data-aos="fade-up" className='w-[95%] h-auto rounded-lg'>
                <form onSubmit={formik.handleSubmit} className='w-full h-auto flex flex-col gap-4'>

                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder='İsim giriniz'
                        autocomplete="off"
                        className={`w-full py-6 px-4 bg-white/10 outline-none rounded-md font-inter text-xl text-white ${formik.touched.name && formik.errors.name ? 'ring-1 ring-red-900' : ''}`}
                    />

                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder='E-mail giriniz'
                        autocomplete="off"
                        className={`w-full py-6 px-4 bg-white/10 outline-none rounded-md font-inter text-xl text-white ${formik.touched.email && formik.errors.email ? 'ring-1 ring-red-900' : ''}`}
                    />

                    <textarea
                        type="text"
                        id="message"
                        name="message"
                        rows={7}
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder='Mesaj giriniz'
                        autocomplete="off"
                        className={`w-full py-4 px-4 bg-white/10 outline-none rounded-md font-inter text-xl text-white resize-none ${formik.touched.message && formik.errors.message ? 'ring-1 ring-red-900' : ''}`}
                    />
                    <button type='submit' className='w-full h-16 font-inter font-semibold bg-white rounded-md mt-4'>{buttonText}</button>
                </form>
            </div>

        </div>
    )
}

export default Contact